import React, { Fragment, useState, useEffect } from "react";
import tools from "../../componentes/tools/tools";
// import TablaPro from "../../componentes/tabla";
import PhoneInput from "../../componentes/react-input-number";
import { useNavigate } from "react-router-dom";

import Select from '../../componentes/select'
// import 'react-intl-tel-input/dist/main.css';
import ItemCarnet from './item_carnet'
import ModalCarnetNuevo from './modal_nuevo_carnet'
import ImgServerr from "../../assets/img/server.gif";

const Default = ({
    esEditar,
    esMesaRegistro,
}) => {
    let server = ''
    const [estatusConexion, setEstatusConexion] = useState(true)
    const [tipoRegistro, setTipoRegistro] = useState(1)
    const [modal, setModal] = useState({estado: 0, datos: {}})
    const [catalogos, setCatalogos] = useState({
        catTipoAsistente: [],
        catNacionalidad: []
    })
    const [selects, setSelects] = useState({
        listExpo: {},
        listTipoCarnet: {},
        listCNacion: {},
        listCTiposAsistente: {},
        listCEstado: {},
        listCCiudad: {},
        catCCiudad: [],
        listFEstado: {},
        listFCiudad: {},
        catFCiudad: [],
        listCPais: {},
        listFRegimenFiscal: {},
        listFUsoCfdi: {},
        listFMetodoPago: {},
        catTipoHospedaje: [],
        txtAplicaDF: false,
        selectedNumCarnets: 1
    })
    const [carnets, setCarnets] = useState([tools.getForm('formCarnet1', { active: 1})])
    const [formContacto, setFormContacto] = useState(tools.getForm('formContacto', { active: 1 }))
    const [formDf, setFormFf] = useState(tools.getForm('formDf', { active: 1 }))
    const [idRegistro, setIdRegistro] = useState(0)
    const [idDf, setIdDf] = useState(0)
    const [refresh, setRefresh] = useState(0)
    const [folioRc, setFolioRc] = useState('')
    const [estatusRegistrar, setEstatusRegistrar] = useState(false)

    const [datosEvento, setDatosEvento] = useState({
        numCarnetInd: 0,
        numCarnetDoble: 0,
        totalAsistentes: 0
    })

    const onClickEnviar = () => {
        let contactoIgnore = ['txtCTel', 'txtCWhatssApp', 'listCTiposAsistente', 'txtCEmpresa', 'txtCEstacion', 'txtCComentarios', 'listCNacion'],
            carnetsIgnore = ['txtEsAnfitrion']
            setEstatusRegistrar(true)
        // if(selects.listTipoCarnet.clave == 4 ||
        //    selects.listTipoCarnet.clave == 5 ||
        //    selects.listTipoCarnet.clave == 6){
            contactoIgnore = [...contactoIgnore, 'txtCTelCode', 'txtCTelPais', 'txtCWhatssAppCode', 'txtCWhatssAppPais', 'txtCEmpresa', 'listCEstado', 'listCCiudad', 'txtCCorreo']
            carnetsIgnore = [...carnetsIgnore, 'listGenero', 'txtWhatsapp', 'txtCorreo', 'listGeneroSeg', 'txtWhatsappSeg', 'txtCorreoSeg', 'listTipoHospedaje']
            // 'txtFechaInicio', 'txtFechaFin'
        // }

        const _formContacto = tools.getForm('formContacto', {claveEtiquetas: '.txtVacios', ignore: contactoIgnore})
        const _formFormDf = tools.getForm('formDf', {claveEtiquetas: '.txtVaciosDf', ignore: ['txtFComentarios', 'txtFNumInt']})
        const _carnets = carnets.map((s, i) => tools.getForm(`formCarnet${i + 1}`, {claveEtiquetas: `.txtVaciosItem${i + 1}`, ignore: carnetsIgnore}))
        const formCarnets = carnets.map((s, i) => {
            // console.log(carnetsIgnore.map((t) => t+(i + 1)))
            return tools.getForm(`formCarnet${i + 1}`, {claveEtiquetas: `.txtVaciosItem${i + 1}`, ignore: carnetsIgnore.map((t) => t+(i + 1))})
        }).map((v) => v.data)
        
        let arrIsEmtyCarnets = 0
        let correoNoValido = 0
        let correosRepetidos = 0

        _carnets.map((s) => {
            if(s.isEmtyCount){
                arrIsEmtyCarnets += 1
            }
        })

        if(selects.listTipoCarnet.clave === null || selects.listTipoCarnet.clave === undefined){
            setEstatusRegistrar(false)
            return tools.notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Hay que seleccionar tipo de carnet para continuar con el registro`,
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        // if(selects.listTipoCarnet.clave === 3 && (selects.listExpo.clave === null || selects.listExpo.clave === undefined)){
        //     return tools.notie.alert({
        //         type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        //         text: `Para el caso de carnet tipo Expositor hay que seleccionar el Expositor correspondiente`,
        //         time: 3, // optional, default = 3, minimum = 1,
        //         position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
        //     })
        // }

        if(_formContacto.isEmtyCount){
            setEstatusRegistrar(false)
            return tools.notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Los datos de contacto no pueden estar vacíos`,
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
              })
        }

        // if(_formFormDf.isEmtyCount && 
        //     (selects.listTipoCarnet.clave === 1 ||
        //     selects.listTipoCarnet.clave === 8 ||
        //     selects.listTipoCarnet.clave === 2)
        // ){
        if(_formFormDf.isEmtyCount && selects.txtAplicaDF)
        {
            setEstatusRegistrar(false)
            return tools.notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Los datos fiscales no pueden estar vacíos`,
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
              })
        }

        if(arrIsEmtyCarnets){
            setEstatusRegistrar(false)
            return tools.notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Los datos de los carnets no pueden estar vacíos`,
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
              })
        }

        // if(selects.listTipoCarnet.clave == 3){
        //     formCarnets.map((v) => {
        //         if(v.txtEsAnfitrion){
        //             correoNoValido += tools.validaCorreo(v.txtCorreo)? 0: 1
        //         }
        //     })

        //     if(correoNoValido > 0){
        //         return tools.notie.alert({
        //             type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        //             text: `Para poder registrar expositores de tipo anfitrión es necesario un correo valido ejemplo (usuario@empresa.mx)`,
        //             time: 4, // optional, default = 3, minimum = 1,
        //             position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
        //           })
        //     }
        // }

        formCarnets.map((v, i) => {
            if(formCarnets.find((s, k) => s.txtCorreo == v.txtCorreo && i !== k)){
                correosRepetidos += 1
            }
        })

        // if(correosRepetidos){
        //     return tools.notie.alert({
        //         type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        //         text: `Existe algun carnet con el mismo correo, favor de revisar y corregir los datos.`,
        //         time: 4, // optional, default = 3, minimum = 1,
        //         position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
        //       })
        // }

        if(selects.listTipoCarnet.clave == 1 || selects.listTipoCarnet.clave == 2 || selects.listTipoCarnet.clave == 8){
            if(!_formContacto.data.txtCComentarios){
                setEstatusRegistrar(false)
                return tools.notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Favor de llenar en el campo de comentarios de contacto, <strong>¿Motivo (descripción breve)?</strong> y por <strong>¿Quien fue autorizado?</strong> este tipo de carnet.`,
                    time: 4, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                  })
            }
        }

        _formContacto.data.txtCWhatssApp = formContacto.data.txtCWhatssApp
        _formContacto.data.txtCWhatssAppCode = formContacto.data.txtCWhatssAppCode
        _formContacto.data.txtCWhatssAppPais = formContacto.data.txtCWhatssAppPais
        _formContacto.data.txtCTel = formContacto.data.txtCTel
        _formContacto.data.txtCTelCode = formContacto.data.txtCTelCode
        _formContacto.data.txtCTelPais = formContacto.data.txtCTelPais

        _formFormDf.data.txtFWhatsapp = formDf.data.txtFWhatsapp
        _formFormDf.data.txtFWhatsappCode = formDf.data.txtFWhatsappCode
        _formFormDf.data.txtFWhatsappPais = formDf.data.txtFWhatsappPais

        fetch('/rt_mesaregistro_registro', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                idPsp: datosEvento.ID_PSP,
                listTipoCarnet: selects.listTipoCarnet.clave,
                listExpo: selects.listExpo.clave? selects.listExpo.clave: 0,
                txtEsCortesia: selects.txtEsCortesia,
                txtAplicaDF: selects.txtAplicaDF,
                formContacto: _formContacto.data,
                formFormDf: _formFormDf.data,
                formCarnets
            })
        })
        .then((response) => response.json())
        .then((response) => {
            setEstatusRegistrar(false)

            if(!response.estado){
                return tools.notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Detalle en el servidor, favor de contactar a soporte técnico`,
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            if(response.estado && response.existe){
                let text = ''

                response.correos.map((v) => {
                    text += v.CORREO + ', '
                })

                text = text.substring(0, text.length - 2)

                return tools.notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Hay usuarios que ya existen, favor de revisar sus datos (${text})`,
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            if(response.excedio){
                return tools.notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Excedio el número maximo de gafets o anfitriones del expositor, pasar con <strong>Ricardo</strong> de <strong>Onexpo</strong> para aumentar registros`,
                    time: 5, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            setTimeout(() => window.location.href = '/mesaregistro', 2000)

            tools.notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Registro realizado exitosamente`,
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
        .catch((err) => {
            return tools.notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Detalle en el servidor, favor de contactar a soporte técnico`,
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }

    const onClickEditarContacto = () => {
        let contactoIgnore = ['txtCTel', 'txtCWhatssApp', 'listCTiposAsistente', 'txtCEmpresa', 'txtCEstacion', 'txtCComentarios', 'listCNacion', 'txtCCorreo']
        contactoIgnore = [...contactoIgnore, 'txtCTelCode', 'txtCTelPais', 'txtCWhatssAppCode', 'txtCWhatssAppPais', 'txtCEmpresa', 'listCEstado', 'listCCiudad']

        const _formContacto = tools.getForm('formContacto', {claveEtiquetas: '.txtVacios', ignore: contactoIgnore})

        if(_formContacto.isEmtyCount){
            return tools.notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Los datos de contacto no pueden estar vacíos`,
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
              })
        }

        _formContacto.data.txtCWhatssApp = formContacto.data.txtCWhatssApp
        _formContacto.data.txtCWhatssAppCode = formContacto.data.txtCWhatssAppCode
        _formContacto.data.txtCWhatssAppPais = formContacto.data.txtCWhatssAppPais
        _formContacto.data.txtCTel = formContacto.data.txtCTel
        _formContacto.data.txtCTelCode = formContacto.data.txtCTelCode
        _formContacto.data.txtCTelPais = formContacto.data.txtCTelPais

        fetch('/rt_mesaregistro_contacto', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                idRc: idRegistro,
                idPsp: datosEvento.ID_PSP,
                listTipoCarnet: selects.listTipoCarnet.clave,
                listExpo: selects.listExpo.clave? selects.listExpo.clave: 0,
                txtEsCortesia: selects.txtEsCortesia,
                txtAplicaDF: selects.txtAplicaDF,
                formContacto: _formContacto.data
            })
        })
        .then((response) => response.json())
        .then((response) => {
            if(!response.estado){
                return tools.notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Detalle en el servidor, vuelva a intenter`,
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            setTimeout(() => window.location.reload('.'), 2000)
            // setTimeout(() => history.push(`/registros/3/${1}`), 2000)

            tools.notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Datos de contacto editados correctamente`,
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
        .catch((err) => {
            return tools.notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Detalle en el servidor, vuelva a intenter`,
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }

    const onClickEditarFactura = () => {
        const _formFormDf = tools.getForm('formDf', {claveEtiquetas: '.txtVaciosDf', ignore: ['txtFComentarios', 'txtFNumInt']})

        if(_formFormDf.isEmtyCount && 
            (selects.listTipoCarnet.clave === 1 ||
            selects.listTipoCarnet.clave === 8 ||
            selects.listTipoCarnet.clave === 2)
        ){
            return tools.notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Los datos fiscales no pueden estar vacíos`,
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
              })
        }

        _formFormDf.data.txtFWhatsapp = formDf.data.txtFWhatsapp
        _formFormDf.data.txtFWhatsappCode = formDf.data.txtFWhatsappCode
        _formFormDf.data.txtFWhatsappPais = formDf.data.txtFWhatsappPais

        fetch('/rt_mesaregistro_factura', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                idRc: idRegistro,
                txtAplicaDF: selects.txtAplicaDF,
                formFormDf: _formFormDf.data
            })
        })
        .then((response) => response.json())
        .then((response) => {
            if(!response.estado){
                return tools.notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Detalle en el servidor, vuelva a intenter`,
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            setTimeout(() => window.location.reload('.'), 2000)
            // setTimeout(() => history.push(`/registros/3/${1}`), 2000)

            tools.notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Datos de facturación editados correctamente`,
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
        .catch((err) => {
            return tools.notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Detalle en el servidor, vuelva a intenter`,
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }

    const onSelectEstado = (item, list) => {
        tools.useFetch({
            ruta: 'rt_catalogos_select_ciudad',
            method: 'POST',
            body: item
        }, (response) => {
            if (list === 1) {
                setSelects((s) => ({
                    ...s,
                    listCEstado: item,
                    catCCiudad: response.datos,
                }))
            }

            if (list === 2) {
                setSelects((s) => ({
                    ...s,
                    listFEstado: item,
                    catFCiudad: response.datos,
                }))
            }
        })
    }

    const onClickNuevoCarnet = (num = 1, tipo = 1) => {
        let arrNuevos = []

        if(typeof num == 'number'){
            if(num > 100){
                return
            }

            if(num < 1){
                return tools.notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `El número de carnets debe de ser mayor a 0`,
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            if(tipo == 2){
                return setCarnets((s) => {
                    return ([
                        ...s,
                        tools.getForm(`formCarnet${carnets.length}`, { active: 1 })
                    ])
                })
            }

            if(num > carnets.length){
                for(let i = 0; i < num - carnets.length; i++){
                    arrNuevos.push(tools.getForm(`formCarnet${carnets.length}`, { active: 1 }))
                }
                
                setCarnets((s) => {
                    return ([
                        ...s,
                        ...arrNuevos
                    ])
                })
            }else{
                setCarnets(carnets.filter((v, i) => i < num ))
            }
        }
    }

    const onClickEliminarCarnet = (indice, row) => {
        if (carnets.length === 1) {
            return
        }

        // carnet
        if(!esEditar){
            let arr = ((carnets.map((s, i) => tools.getForm(`formCarnet${i + 1}`, {claveEtiquetas: `.txtVaciosItem${i + 1}`, ignore: ['txtEsAnfitrion']}))).filter((v, i) => i !== indice))
            setCarnets(arr)
            setSelects((v) => ({
                ...v,
                selectedNumCarnets: arr.length
            }))
            return
        }

        let item = carnets.map((s, i) => tools.getForm(`formCarnet${i + 1}`, {claveEtiquetas: `.txtVaciosItem${i + 1}`, ignore: ['txtEsAnfitrion']})).filter((v, i) => i === indice)
        item = item[0].data

        tools.notie.confirm({
            text: `Se eliminara el carnet de <strong>${item.txtNombre} ${item.txtApellidos}</strong> ¿Esta de acuerdo?`,
            submitText: 'Si, eliminar', // optional, default = 'Yes'
            cancelText: 'No, cancelar', // optional, default = 'Cancel'
            position: 'top', // optional, default = 'top', enum: ['top', 'bottom']
            submitCallback: () => {
                fetch('/rt_mesaregistro_eliminar_carnet', {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({
                        idRc: idRegistro,
                        idSub: row.idSub
                    })
                })
                .then((response) => response.json())
                .then((response) => {
                    setRefresh((s) => !s)
                    tools.notie.alert({
                        type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                        text: `Registro eliminado correctamente`,
                        time: 2, // optional, default = 3, minimum = 1,
                        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                    })
                })
                .catch((err) => {
                    return tools.notie.alert({
                        type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                        text: `Detalle en el servidor, vuelva a intentar`,
                        time: 3, // optional, default = 3, minimum = 1,
                        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                    })
                })
            }, // optional
            cancelCallback: () => {
            }// optional
        })
    }

    const onClickEditarCarnet = (indice, row) => {
        let vacios = ''
        let item = carnets.map((s, i) => {
            let form = tools.getForm(`formCarnet${i + 1}`, {claveEtiquetas: `.txtVaciosItem${i + 1}`, ignore: ['txtEsAnfitrion',`txtCorreo${i + 1}`,`listGenero${i + 1}`,`txtWhatsapp${i + 1}`, `txtCorreo${i + 1}`,`listGeneroSeg${i + 1}`,`txtWhatsappSeg${i + 1}`]})
            if(form.isEmtyCount){
                vacios = form.isEmtyString
            }
            return form
        }).filter((v, i) => i === indice)
        item = item[0].data

        if(vacios.length){
            return  tools.notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Faltan los siguientes campos (${vacios})`,
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        fetch('/rt_mesaregistro_editar_carnet', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                idRc: idRegistro,
                idSub: row.idSub,
                id1: row.id,
                id2: row.id2,
                listTipoCarnet: selects.listTipoCarnet.clave,
                formCarnets: [item]
            })
        })
        .then((response) => response.json())
        .then((response) => {
            setRefresh((s) => !s)
            tools.notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Registro editado correctamente`,
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
        .catch((err) => {
            return tools.notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Detalle en el servidor, vuelva a intentar`,
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }

    useEffect(() => {
        let isS = true;
        let id = 0

        if(esEditar){
            id = tools.getParamsId(window.location.pathname)
            setIdRegistro(id)
        }

        fetch('/rt_catalogos_mesa', {
            method: 'POST',
            body: JSON.stringify({
                esEditar, id, esMesaRegistro
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then((response) => response.json())
        .then((response) => {
            if(!isS){
                return 
            }

            if(tipoRegistro == 2){
                response.datos.catTipoCarnets = response.datos.catTipoCarnets.filter((v) => v.clave == 3)
            }

            setDatosEvento((s) => ({...s, ...response.datosEvento}))
            setCatalogos((s) => ({
                ...s,
                ...response.datos
            }))

            if(esEditar){
                let detalleContacto = response.detalleCarnet.convencion
                let detalleAsistentes = response.detalleCarnet.asistentes
                let datosFiscales = response.detalleCarnet.df

                // Select de contacto
                let selectedNumCarnets = response.detalleCarnet.selectedNumCarnets
                let selectCarnet = {label: detalleContacto.tipo_carnet_desc, clave:  detalleContacto.tipo_carnet}
                let selectNacion = {label: detalleContacto.nacionalidad_desc, clave:  detalleContacto.listCNacion? '1': '0'}
                let selectEdo = {label: detalleContacto.listCEstadoDesc, clave:  detalleContacto.listCEstado}
                let selectCd = {label: detalleContacto.listCCiudadDesc, clave:  Number(detalleContacto.listCCiudad)}

                //Select de datos fiscales
                let selectEdoDf = {label: datosFiscales.listFEstadoDesc, clave: datosFiscales.listFEstado}
                let selectCdDf = {label: datosFiscales.listFCiudadDesc, clave: datosFiscales.listFCiudad}
                let selectRf = {label: datosFiscales.listFRegimenFiscalDesc, clave:  datosFiscales.listFRegimenFiscal}
                let selectUsoCfdi = {label: datosFiscales.listFUsoCfdiDesc, clave: datosFiscales.listFUsoCfdi}
                let selectFormaPago = {label: datosFiscales.listFFormaPagoDesc, clave: datosFiscales.listFFormaPago}

                setCarnets(detalleAsistentes.map((v) => ({...tools.getForm('formCarnet1', { active: 1}), data: v})))
                
                setSelects((s) => ({ ...s, 
                    selectedNumCarnets: selectedNumCarnets,
                    listTipoCarnet: selectCarnet, 
                    listCNacion: selectNacion,
                    listCCiudad: selectCd,
                    catCCiudad: response.datos.catCiudad,
                    listCEstado: selectEdo,
                    txtAplicaDF: datosFiscales.idDf,
                    // txtAplicaDF: selectCarnet.clave == 1 || selectCarnet.clave == 2 || selectCarnet.clave == 8,
                    // txtEsCortesia: selectCarnet.clave == 1 || selectCarnet.clave == 2? false: selectCarnet.clave == 8,
                    listFEstado: selectEdoDf,
                    listFCiudad: selectCdDf,
                    catFCiudad: response.datos.catFCiudad,
                    listFRegimenFiscal: selectRf,
                    listFUsoCfdi: selectUsoCfdi,
                    listFMetodoPago: selectFormaPago,
                    txtEsCortesia: detalleContacto.txtEsCortesia,
                }))

                setFormContacto((v) => ({
                    ...v,
                    data: detalleContacto
                }))

                setIdDf(datosFiscales.idDf)

                setFormFf((v) => ({
                    ...v,
                    data: datosFiscales
                }))
                
                setFolioRc(detalleContacto.folio)
            }
        })

        return () => (isS = false);
    }, [refresh, tipoRegistro]);

    useEffect(() => {
        let isS = true;
        if (window.eventos) {
            window.eventos.tipoRegistro()
                .then((response) => {
                    if (isS) {
                        setTipoRegistro(response)
                    }
                })
                .catch(() => {
                    if (isS) {
                        setTipoRegistro(1)
                    }
                })
        }
        return () => (isS = false);
    }, [])

    const verificaConexion = async () => {
        // await window.eventos.reload()
        if(!window.eventos){
          return
        }
  
        server = await window.eventos.ip()
        server += '/conexion' 
        setTimeout(() => {
            fetch(server, {
                method: 'GET',
                headers: {"Content-Type":"application/json"}
            })
            .then(async (response) => {
              if(response.status == 500){
                setEstatusConexion(false)
              }else if(response.status == 200){
                if(estatusConexion == false){
                    setEstatusConexion(true)
                }
              }
              verificaConexion()
            })
            .catch(async (err) => {
              if(estatusConexion){
                console.log('Desconectado')
                setEstatusConexion(false)
              }
              verificaConexion()
            })
        }, 1000)
    }
    
    useEffect(() => {
        let isS = true;
        verificaConexion(isS) 
        return () => (isS = false);
    }, [estatusConexion]);

    return (
        <div>
            <Fragment>
                <div className="col-sm-12 mt-5 registros-nuevos">
                    <div className="card mt-5">
                        <div className="card-header p-0 mt-3">
                            <div className='row p-0 m-0 d-flex justify-content-center'>                                
                                <div className="col-sm-10 p-0 m-0 d-flex justify-content-center">
                                    <div style={{position:'absolute', left:0, top:-60}}>
                                    {estatusConexion == false?
                                    <button className="btn btn-danger btn-sm" 
                                        onClick={async () => {
                                            if(window.eventos){
                                                await window.eventos.paginaCargando()
                                            }
                                        }}
                                    >Sin conexión, reintentando...</button>:<img src={ImgServerr} width={40}/>}
                                    </div>

                                    {esMesaRegistro?<button type="button" 
                                        style={{position: 'absolute', left: 0}}
                                        className="btn btn-primary " 
                                        onClick={() => {
                                            // window.history.back()
                                            window.location.href = '/mesaregistro'
                                        }}
                                    >
                                        <i className="fa fa-arrow-left" aria-hidden="true"></i>
                                    </button>: ''}
                                    <h4 className="">{!esEditar? 'Nuevo Registro': `Editar Registro ${folioRc?`#${folioRc}`:``}`}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="datatable-react mt-4 d-flex justify-content-center">
                            <div className="col-sm-10 mt-0">
                                <div className="row mb-4 carnet-select">
                                    <div className="col-md-6">
                                        <label style={{ fontWeight: 600 }}>Tipo de registro <span className="text-danger">*</span>
                                        </label>
                                        <Select
                                            disabled={esEditar}
                                            className="form-control form-control-md border border-dark"
                                            data-label="Tipo Carnet"
                                            placeholder='Seleccionar tipo carnet'
                                            id="listTipoCarnet"
                                            name="listTipoCarnet"
                                            value={selects.listTipoCarnet.clave}
                                            datos={catalogos.catTipoCarnets}
                                            onChange={({ item }) => {
                                                setSelects((s) => ({ 
                                                    ...s, 
                                                    listTipoCarnet: item, 
                                                    // txtAplicaDF: item.clave == 1 || item.clave == 2 || item.clave == 8,
                                                    // txtEsCortesia: item.clave == 1 || item.clave == 2? false: item.clave == 8
                                                }))
                                            }}
                                        />
                                    </div>
                                    {/* {selects.listTipoCarnet.clave === 3 ? <div className="col-md-6">
                                        <label
                                            style={{ fontWeight: 600 }}
                                            htmlFor="papellidos"
                                        >
                                            Expositor <span className="text-danger">*</span>
                                        </label>
                                        <Select
                                            className="form-control form-control-md border border-dark"
                                            data-label="Tipo Carnet"
                                            placeholder='Seleccionar expositor'
                                            id="listExpo"
                                            name="listExpo"
                                            value={selects.listExpo.clave}
                                            datos={catalogos.catExpo}
                                            onChange={({ item }) => {
                                                setSelects((s) => ({ ...s, listExpo: item }))
                                            }}
                                        />
                                    </div> : ''} */}
                                </div>
                                <div>
                                {/* {!(selects.listTipoCarnet.clave == 3)? */}
                                <form id="formContacto" className="row form theme-form" >
                                    <div className="col-12 contacto-title">
                                        <h5 className="mb-3 text-info">Datos Contacto</h5>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label
                                                style={{ fontWeight: 600 }}
                                                htmlFor="pnombre"
                                            >
                                                Nombre(s)<span className="text-danger">*</span>
                                            </label>
                                            <input className="form-control border border-dark"
                                                id="txtCNombre"
                                                name="txtCNombre" type="text" placeholder="Nombre(s)" 
                                                defaultValue={formContacto.data.txtCNombre}
                                                />
                                            <span className="text-danger txtVacios txtCNombre" style={{display: 'none'}}>Falta completar (Nombre(s))</span>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label
                                                style={{ fontWeight: 600 }}
                                                htmlFor="papellidos"
                                            >
                                                Apellidos<span className="text-danger">*</span>
                                            </label>
                                            <input
                                                className="form-control border border-dark"
                                                id="txtCApellidos"
                                                name="txtCApellidos"
                                                type="text"
                                                placeholder="Apellidos"
                                                defaultValue={formContacto.data.txtCApellidos}
                                            />
                                            <span className="text-danger txtVacios txtCApellidos" style={{display: 'none'}}>Falta completar (Apellidos)</span>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label
                                                style={{ fontWeight: 600 }}
                                                htmlFor="pcorreo"
                                            >
                                                Correo Electrónico
                                            </label>
                                            <input
                                                className="form-control border border-dark"
                                                id="txtCCorreo"
                                                name="txtCCorreo"
                                                type="text"
                                                placeholder="Correo Electrónico"
                                                defaultValue={formContacto.data.txtCCorreo}
                                            />
                                            <span className="text-danger txtVacios txtCCorreo" style={{display: 'none'}}>Falta completar (Correo Electrónico)</span>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label
                                                style={{ fontWeight: 600 }}
                                                htmlFor="pwhatsapp"
                                            >
                                                Whatsapp
                                            </label>
                                            <br></br>
                                            <PhoneInput
                                                id='txtCWhatssApp'
                                                name='txtCWhatssApp'
                                                className={'border border-dark'}
                                                value={formContacto.data.txtCWhatssApp}
                                                code={formContacto.data.txtCWhatssAppCode}
                                                iso={formContacto.data.txtCWhatssAppPais}
                                                label='Whatsapp'
                                                onChange={(datos) => {
                                                    setFormContacto((s) => ({
                                                        ...s,
                                                        data: {
                                                            ...s.data,
                                                            txtCWhatssApp: datos.number,
                                                            txtCWhatssAppCode: datos.code,
                                                            txtCWhatssAppPais: datos.iso,
                                                        },
                                                    }));
                                                }}
                                            />
                                            <span className="text-danger txtVacios txtCWhatssApp" style={{display: 'none'}}>Falta completar (Whatsapp)</span>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label
                                                style={{ fontWeight: 600 }}
                                                htmlFor="ptelefono"
                                            >
                                                Teléfono
                                            </label>
                                            <br></br>
                                            <PhoneInput
                                                id='txtCTel'
                                                name='txtCTel'
                                                className={'border border-dark'}
                                                value={formContacto.data.txtCTel}
                                                code={formContacto.data.txtCTelCode}
                                                iso={formContacto.data.txtCTelPais}
                                                label='Teléfono'
                                                onChange={(datos) => {
                                                    setFormContacto((s) => ({
                                                        ...s,
                                                        data: {
                                                            ...s.data,
                                                            txtCTel: datos.number,
                                                            txtCTelCode: datos.code,
                                                            txtCTelPais: datos.iso,
                                                        },
                                                    }));
                                                }}
                                            />
                                            <span className="text-danger txtVacios txtCTel" style={{display: 'none'}}>Falta completar (Teléfono)</span>
                                        </div>
                                    </div>
                                    {/* {
                                    selects.listCTiposAsistente.clave === 1 || 
                                    selects.listCTiposAsistente.clave === 2 ?
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label
                                                htmlFor="ptipo"
                                                style={{ fontWeight: 600 }}
                                            >
                                                Tipo Asistente
                                            </label>
                                            <Select
                                                id="listCTiposAsistente"
                                                name="listCTiposAsistente"
                                                className="form-control form-control-md border border-dark"
                                                data-label="Tipo Asistente"
                                                placeholder="Seleccionar tipo asistente"
                                                datos={[{clave: '', label: 'Seleccionar tipo asistentes'}, ...catalogos.catTipoAsistente]}
                                                value={selects.listCTiposAsistente.clave}
                                                onChange={({ item }) => {
                                                    setSelects((s) => ({
                                                        ...s,
                                                        listCTiposAsistente: item
                                                    }))
                                                }}
                                            />
                                            <span className="text-danger txtVacios listCTiposAsistente" style={{display: 'none'}}>Falta completar (Tipo Asistente)</span>
                                        </div>
                                    </div>: ''} */}
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label
                                                style={{ fontWeight: 600 }}
                                                htmlFor="pestacion_servicio"
                                            >
                                                {selects.listCTiposAsistente.clave === 1 ? 'Grupo Gasolinero' :
                                                    selects.listCTiposAsistente.clave === 2 ? 'Empresa' :
                                                        selects.listCTiposAsistente.clave === 3 ? 'Empresa o Marca' :
                                                            selects.listCTiposAsistente.clave === 4 ? 'Institución' : 'Empresa'}
                                                <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                className="form-control border border-dark"
                                                id="txtCEmpresa"
                                                name="txtCEmpresa"
                                                type="tel"
                                                defaultValue={formContacto.data.txtCEmpresa}
                                                placeholder={`${selects.listCTiposAsistente.clave === 1 ? 'Grupo Gasolinero' :
                                                    selects.listCTiposAsistente.clave === 2 ? 'Empresa' :
                                                        selects.listCTiposAsistente.clave === 3 ? 'Empresa o Marca' :
                                                            selects.listCTiposAsistente.clave === 4 ? 'Institución' : 'Empresa'}`}
                                            />
                                            <span className="text-danger txtVacios txtCEmpresa" style={{display: 'none'}}>Falta completar ({`${selects.listCTiposAsistente.clave === 1 ? 'Grupo Gasolinero' :
                                                    selects.listCTiposAsistente.clave === 2 ? 'Empresa' :
                                                        selects.listCTiposAsistente.clave === 3 ? 'Empresa o Marca' :
                                                            selects.listCTiposAsistente.clave === 4 ? 'Institución' : 'Empresa'}`})</span>
                                        </div>
                                    </div>
                                    {selects.listCTiposAsistente.clave === 1 ?
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label
                                                    style={{ fontWeight: 600 }}
                                                    htmlFor="pgrupo_gasolinero"
                                                >
                                                    Estación
                                                </label>
                                                <input
                                                    className="form-control border border-dark"
                                                    id="txtCEstacion"
                                                    name="txtCEstacion"
                                                    type="text"
                                                    placeholder="Estación"
                                                />
                                                <span className="text-danger txtVacios txtCEstacion" style={{display: 'none'}}>Falta completar (Estación)</span>
                                            </div>
                                        </div>
                                        : ''}
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label
                                                htmlFor="pnacionalidad"
                                                style={{ fontWeight: 600 }}
                                            >
                                                Nacionalidad
                                            </label>
                                            <Select
                                                className="form-control form-control-md border border-dark"
                                                data-label="Estado"
                                                placeholder="Seleccionar nacionalidad"
                                                id="listCNacion"
                                                name="listCNacion"
                                                datos={[{clave: '', label: 'Seleccionar nacionalidad'}, ...catalogos.catNacionalidad]}
                                                value={selects.listCNacion.clave}
                                                onChange={({ item }) => {
                                                    setSelects((s) => ({
                                                        ...s,
                                                        listCNacion: item
                                                    }))
                                                }}
                                            />
                                            <span className="text-danger txtVacios listCNacion" style={{display: 'none'}}>Falta completar (Nacionalidad)</span>
                                        </div>
                                    </div>
                                    {/* Campos de Mexicanos */}
                                    {selects.listCNacion.clave == 0 ?
                                        <Fragment>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label
                                                        htmlFor="pestado"
                                                        style={{ fontWeight: 600 }}
                                                    >
                                                        Estados
                                                    </label>
                                                    <Select
                                                        className="form-control form-control-md border border-dark"
                                                        data-label="Estado"
                                                        placeholder="Seleccionar estados"
                                                        id="listCEstado"
                                                        name="listCEstado"
                                                        datos={catalogos.catEstados}
                                                        value={selects.listCEstado.clave}
                                                        onChange={({ item }) => {
                                                            onSelectEstado(item, 1)
                                                        }}
                                                    />
                                                    <span className="text-danger txtVacios listCEstado" style={{display: 'none'}}>Falta completar (Estados)</span>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label
                                                        htmlFor="pciudad"
                                                        style={{ fontWeight: 600 }}
                                                    >
                                                        Ciudad
                                                    </label>
                                                    <Select
                                                        className="form-control form-control-md border border-dark"
                                                        data-label="Estado"
                                                        placeholder="Seleccionar ciudad"
                                                        id="listCCiudad"
                                                        name="listCCiudad"
                                                        value={selects.listCCiudad.clave}
                                                        datos={selects.catCCiudad}
                                                        onChange={({ item }) => {
                                                            setSelects((s) => ({
                                                                ...s,
                                                                listCCiudad: item
                                                            }))
                                                        }}
                                                    />
                                                    <span className="text-danger txtVacios listCCiudad" style={{display: 'none'}}>Falta completar (Ciudad)</span>
                                                </div>
                                            </div>
                                        </Fragment>
                                        : ''}
                                    {/* Campos de Extranjeros */}
                                    {selects.listCNacion.clave == 1 ?
                                        <Fragment>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label
                                                        style={{ fontWeight: 600 }}
                                                        htmlFor="pextranjero_pais"
                                                    >
                                                        País
                                                    </label>
                                                    <Select
                                                        className="form-control form-control-md border border-dark"
                                                        data-label="País"
                                                        placeholder="Seleccionar país"
                                                        id="listCPais"
                                                        name="listCPais"
                                                        datos={catalogos.catPaises}
                                                        value={selects.listCPais.clave}
                                                        onChange={({ item }) => {
                                                            setSelects((s) => ({
                                                                ...s,
                                                                listCPais: item
                                                            }))
                                                        }}
                                                    />
                                                </div>
                                                <span className="text-danger txtVacios listCPais" style={{display: 'none'}}>Falta completar (País)</span>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label
                                                        style={{ fontWeight: 600 }}
                                                        htmlFor="pextranjero_estado"
                                                    >
                                                        Estado/provincia
                                                    </label>
                                                    <input
                                                        className="form-control border border-dark"
                                                        id="txtCEstado"
                                                        name="txtCEstado"
                                                        type="text"
                                                        placeholder="Estado/provincia"
                                                    />
                                                </div>
                                                <span className="text-danger txtVacios txtCEstado" style={{display: 'none'}}>Falta completar (Estado/provincia)</span>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label
                                                        style={{ fontWeight: 600 }}
                                                        htmlFor="pextranjero_ciudad"
                                                    >
                                                        Ciudad
                                                    </label>
                                                    <input
                                                        className="form-control border border-dark"
                                                        id="txtCCiudad"
                                                        name="txtCCiudad"
                                                        type="text"
                                                        placeholder="Ciudad"
                                                    />
                                                    <span className="text-danger txtVacios txtCCiudad" style={{display: 'none'}}>Falta completar (Ciudad)</span>
                                                </div>
                                            </div>
                                        </Fragment>
                                        : ''}
                                        <div className="col-md-12">
                                            <label
                                                htmlFor="fcomentarios"
                                                style={{ fontWeight: 600 }}
                                            >
                                                Comentarios de contacto<span className="text-red">*</span>
                                            </label>
                                            <div className="form-group">
                                                <textarea
                                                    id="txtCComentarios"
                                                    name="txtCComentarios"
                                                    cols="30"
                                                    rows="5"
                                                    className="w-100 border border-dark"
                                                    defaultValue={formContacto.data.txtCComentarios}
                                                ></textarea>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                        {/* {(selects.listTipoCarnet.clave === 1 || selects.listTipoCarnet.clave === 8  || selects.listTipoCarnet.clave === 2)? */}
                                            <Fragment>
                                                <div className="form-group">
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input border-dark"
                                                            id={`txtAplicaDF`} 
                                                            name="txtAplicaDF"
                                                            type="checkbox"
                                                            placeholder="Datos fiscales"
                                                            checked={selects.txtAplicaDF? true: false}
                                                            // value={datos.txtEsAnfitrion? 1: 0}
                                                            // {...(datos.txtEsAnfitrion? checked: '')}
                                                            onChange={(e) => {
                                                                e.persist()
                                                                setSelects((s) => ({
                                                                    ...s,
                                                                    txtAplicaDF: !s.txtAplicaDF
                                                                }))
                                                            }}
                                                        />
                                                        <label className="form-check-label" htmlFor="flexCheckDefault"  style={{ fontWeight: 600 }}>
                                                            Requiere factura
                                                        </label>
                                                        {/* <span className={`text-danger txtVaciosItem${indice + 1} txtEsAnfitrion${indice + 1}`} style={{display: 'none'}}>Falta completar Anfitrion</span>   */}
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input border-dark"
                                                            id={`txtEsCortesia`} 
                                                            name="txtEsCortesia"
                                                            type="checkbox"
                                                            placeholder="Es Cortesia"
                                                            checked={selects.txtEsCortesia? true: false}
                                                            onChange={(e) => {
                                                                e.persist()
                                                                setSelects((s) => ({
                                                                    ...s,
                                                                    txtEsCortesia: !s.txtEsCortesia
                                                                }))
                                                            }}
                                                        />
                                                        <label className="form-check-label" htmlFor="flexCheckDefault"  style={{ fontWeight: 600 }}>
                                                            Es cortesía
                                                        </label>
                                                        {/* <span className={`text-danger txtVaciosItem${indice + 1} txtEsAnfitrion${indice + 1}`} style={{display: 'none'}}>Falta completar Anfitrion</span>   */}
                                                    </div>
                                                </div>
                                            </Fragment> 
                                        {/* : ''} */}
                                        </div>
                                        {esEditar? <div className="col-md-12">
                                            <div className="row d-flex justify-content-end">
                                                <div className="col-md-4">
                                                    <button type="button" className="btn btn-primary btn-lg btn-block"
                                                        onClick={() => onClickEditarContacto()}
                                                    >Guardar Contacto</button>
                                                </div>
                                            </div>
                                        </div>: ''}
                                </form>
                                {/* : ''} */}
                                   
                                    {/* Datos de facturación */}
                                    {/* {(selects.listTipoCarnet.clave === 1 || selects.listTipoCarnet.clave === 8  || selects.listTipoCarnet.clave === 2) && selects.txtAplicaDF? */}
                                    {selects.txtAplicaDF?
                                    <Fragment>
                                        <div className="row mb-4 carnet-select">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <form id="formDf" className="row form theme-form">
                                                        <div className="col-12">
                                                            <h5 className="mb-3 text-info">Datos Fiscales</h5>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label
                                                                    style={{ fontWeight: 600 }}
                                                                    htmlFor="frazon_social"
                                                                >
                                                                    Razón Social<span className="text-danger">*</span>
                                                                </label>
                                                                <input
                                                                    className="form-control border border-dark"
                                                                    id="txtFRs"
                                                                    name="txtFRs"
                                                                    type="text"
                                                                    placeholder="Razón Social"
                                                                    defaultValue={formDf.data.txtFRs}
                                                                />
                                                                <span className="text-danger txtVaciosDf txtFRs" style={{ display: 'none' }}>Falta completar (Razón Social)</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label
                                                                    style={{ fontWeight: 600 }}
                                                                    htmlFor="frfc"
                                                                >
                                                                    RFC<span className="text-danger">*</span>
                                                                </label>
                                                                <input
                                                                    className="form-control border border-dark"
                                                                    id="txtFRfc"
                                                                    name="txtFRfc"
                                                                    type="text"
                                                                    placeholder="RFC"
                                                                    defaultValue={formDf.data.txtFRfc}
                                                                />
                                                                <span className="text-danger txtVaciosDf txtFRfc" style={{ display: 'none' }}>Falta completar (RFC)</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label
                                                                    style={{ fontWeight: 600 }}
                                                                    htmlFor="fregimen_fiscal"
                                                                >
                                                                    Régimen Fiscal<span className="text-danger">*</span>
                                                                </label>
                                                                <Select
                                                                    className="form-control form-control-md border border-dark"
                                                                    data-label="Régimen Fiscal"
                                                                    placeholder="Seleccionar Régimen Fiscal"
                                                                    id="listFRegimenFiscal"
                                                                    name="listFRegimenFiscal"
                                                                    value={selects.listFRegimenFiscal.clave}
                                                                    datos={catalogos.catRegimenFiscal}
                                                                    onChange={({ item }) => {
                                                                        setSelects((s) => ({
                                                                            ...s,
                                                                            listFRegimenFiscal: item
                                                                        }))
                                                                    }}
                                                                />
                                                                <span className="text-danger txtVaciosDf listFRegimenFiscal" style={{ display: 'none' }}>Falta completar (Régimen Fiscal)</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label
                                                                    style={{ fontWeight: 600 }}
                                                                    htmlFor="fmetodo_pago"
                                                                >
                                                                    Forma de pago<span className="text-danger">*</span>
                                                                </label>
                                                                <Select
                                                                    className="form-control form-control-md border border-dark"
                                                                    data-label="método de forma de pago"
                                                                    placeholder="Seleccionar forma de pago"
                                                                    id="listFFormaPago"
                                                                    name="listFFormaPago"
                                                                    value={selects.listFMetodoPago.clave}
                                                                    datos={catalogos.catFormaPago}
                                                                    onChange={({ item }) => {
                                                                        setSelects((s) => ({
                                                                            ...s,
                                                                            listFMetodoPago: item
                                                                        }))
                                                                    }}
                                                                />
                                                                <span className="text-danger txtVaciosDf listFFormaPago" style={{ display: 'none' }}>Falta completar (Forma de pago)</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label
                                                                    style={{ fontWeight: 600 }}
                                                                    htmlFor="fcfdi"
                                                                >
                                                                    Uso CFDI<span className="text-danger">*</span>
                                                                </label>
                                                                <Select
                                                                    className="form-control form-control-md border border-dark"
                                                                    data-label="Estado"
                                                                    placeholder="Seleccionar Uso CFDI"
                                                                    id="listFUsoCfdi"
                                                                    name="listFUsoCfdi"
                                                                    value={selects.listFUsoCfdi.clave}
                                                                    datos={catalogos.catUsoCfdi}
                                                                    onChange={({ item }) => {
                                                                        setSelects((s) => ({
                                                                            ...s,
                                                                            listFUsoCfdi: item
                                                                        }))
                                                                    }}
                                                                />
                                                                <span className="text-danger txtVaciosDf listFUsoCfdi" style={{ display: 'none' }}>Falta completar (Uso CFDI)</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label
                                                                    style={{ fontWeight: 600 }}
                                                                    htmlFor="fcalle"
                                                                >
                                                                    Calle<span className="text-danger">*</span>
                                                                </label>
                                                                <input
                                                                    className="form-control border border-dark"
                                                                    id="txtFCalle"
                                                                    name="txtFCalle"
                                                                    type="text"
                                                                    placeholder="Calle"
                                                                    defaultValue={formDf.data.txtFCalle}
                                                                />
                                                                <span className="text-danger txtVaciosDf txtFCalle" style={{ display: 'none' }}>Falta completar (Calle)</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label
                                                                    style={{ fontWeight: 600 }}
                                                                    htmlFor="fcolonia"
                                                                >
                                                                    Colonia<span className="text-danger">*</span>
                                                                </label>
                                                                <input
                                                                    className="form-control border border-dark"
                                                                    id="txtFColonia"
                                                                    name="txtFColonia"
                                                                    type="text"
                                                                    placeholder="Colonia"
                                                                    defaultValue={formDf.data.txtFColonia}
                                                                />
                                                                <span className="text-danger txtVaciosDf txtFColonia" style={{ display: 'none' }}>Falta completar (Colonia)</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label
                                                                    style={{ fontWeight: 600 }}
                                                                    htmlFor="fnumero_exterior"
                                                                >
                                                                    Núm. Exterior<span className="text-danger">*</span>
                                                                </label>
                                                                <input
                                                                    className="form-control border border-dark"
                                                                    id="txtFNumExt"
                                                                    name="txtFNumExt"
                                                                    type="text"
                                                                    placeholder="Núm. Exterior"
                                                                    defaultValue={formDf.data.txtFNumExt}
                                                                />
                                                                <span className="text-danger txtVaciosDf txtFNumExt" style={{ display: 'none' }}>Falta completar (Núm. Exterior)</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label
                                                                    style={{ fontWeight: 600 }}
                                                                    htmlFor="fnumero_interior"
                                                                >
                                                                    Núm. Interior
                                                                </label>
                                                                <input
                                                                    className="form-control border border-dark"
                                                                    id="txtFNumInt"
                                                                    name="txtFNumInt"
                                                                    type="text"
                                                                    placeholder="Núm. Interior"
                                                                    defaultValue={formDf.data.txtFNumInt}
                                                                />
                                                                <span className="text-danger txtVaciosDf txtFNumInt" style={{ display: 'none' }}>Falta completar (Núm. Interior)</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label
                                                                    style={{ fontWeight: 600 }}
                                                                    htmlFor="fcodigo_postal"
                                                                >
                                                                    Código Postal<span className="text-danger">*</span>
                                                                </label>
                                                                <input
                                                                    className="form-control border border-dark"
                                                                    id="txtFCp"
                                                                    name="txtFCp"
                                                                    type="text"
                                                                    placeholder="Código Postal"
                                                                    defaultValue={formDf.data.txtFCp}
                                                                />
                                                                <span className="text-danger txtVaciosDf txtFCp" style={{ display: 'none' }}>Falta completar (Código Postal)</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label
                                                                    style={{ fontWeight: 600 }}
                                                                    htmlFor="festado"
                                                                >
                                                                    Estado<span className="text-danger">*</span>
                                                                </label>
                                                                <Select
                                                                    className="form-control form-control-md border border-dark"
                                                                    data-label="Estado"
                                                                    placeholder="Seleccionar estado"
                                                                    id="listFEstado"
                                                                    name="listFEstado"
                                                                    value={selects.listFEstado.clave}
                                                                    datos={catalogos.catEstados}
                                                                    onChange={({ item }) => {
                                                                        onSelectEstado(item, 2)
                                                                    }}
                                                                />
                                                                <span className="text-danger txtVaciosDf listFEstado" style={{ display: 'none' }}>Falta completar (Estado)</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label
                                                                    style={{ fontWeight: 600 }}
                                                                    htmlFor="fciudad"
                                                                >
                                                                    Ciudad<span className="text-danger">*</span>
                                                                </label>
                                                                <Select
                                                                    className="form-control form-control-md border border-dark"
                                                                    data-label="Estado"
                                                                    placeholder="Seleccionar ciudad"
                                                                    id="listFCiudad"
                                                                    name="listFCiudad"
                                                                    value={selects.listFCiudad.clave}
                                                                    datos={selects.catFCiudad}
                                                                    onChange={({ item }) => {
                                                                        setSelects((s) => ({
                                                                            ...s,
                                                                            listFCiudad: item
                                                                        }))
                                                                    }}
                                                                />
                                                                <span className="text-danger txtVaciosDf listFCiudad" style={{ display: 'none' }}>Falta completar (Ciudad)</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label
                                                                    style={{ fontWeight: 600 }}
                                                                    htmlFor="fcorreo"
                                                                >
                                                                    Correo Electrónico<span className="text-danger">*</span>
                                                                </label>
                                                                <input
                                                                    className="form-control border border-dark"
                                                                    id="txtFCorreo"
                                                                    name="txtFCorreo"
                                                                    type="text"
                                                                    placeholder="Correo Electrónico"
                                                                    defaultValue={formDf.data.txtFCorreo}
                                                                />
                                                                <span className="text-danger txtVaciosDf txtFCorreo" style={{ display: 'none' }}>Falta completar (Correo Electrónico)</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label
                                                                    style={{ fontWeight: 600 }}
                                                                    htmlFor="ftelefono"
                                                                >
                                                                    Teléfono<span className="text-danger">*</span>
                                                                </label>
                                                                <br></br>
                                                                <PhoneInput
                                                                    id='txtFWhatsapp'
                                                                    className={'border border-dark'}
                                                                    value={formDf.data.txtFWhatsapp}
                                                                    code={formDf.data.txtFWhatsappCode}
                                                                    iso={formDf.data.txtFWhatsappPais}
                                                                    label='Whatsapp'
                                                                    onChange={(datos) => {
                                                                        setFormFf((s) => ({
                                                                            ...s,
                                                                            data: {
                                                                                ...s.data,
                                                                                txtFWhatsapp: datos.number,
                                                                                txtFWhatsappCode: datos.code,
                                                                                txtFWhatsappPais: datos.iso,
                                                                            },
                                                                        }));
                                                                    }}
                                                                />
                                                                <span className="text-danger txtVaciosDf txtFWhatsapp" style={{ display: 'none' }}>Falta completar (Teléfono)</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <label
                                                                htmlFor="fcomentarios"
                                                                style={{ fontWeight: 600 }}
                                                            >
                                                                Comentarios de datos fiscales
                                                            </label>
                                                            <div className="form-group">
                                                                <textarea
                                                                    id="txtFComentarios"
                                                                    name="txtFComentarios"
                                                                    cols="30"
                                                                    rows="5"
                                                                    className="w-100 border border-dark"
                                                                    defaultValue={formDf.data.txtFComentarios}
                                                                ></textarea>
                                                            </div>
                                                        </div>
                                                        {esEditar?<div className="col-md-12">
                                                            <div className="row d-flex justify-content-end">
                                                                <div className="col-md-4">
                                                                    <button type="button" className="btn btn-primary btn-lg btn-block"
                                                                        onClick={() => onClickEditarFactura()}
                                                                    >Guardar Datos Facturación</button>
                                                                </div>
                                                            </div>
                                                        </div>: ''}
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </Fragment>
                                    : ''}
                                    <Fragment>
                                    <div className="row mb-4 carnet-select">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label
                                                    style={{ fontWeight: 600 }}
                                                    htmlFor="fmetodo_pago"
                                                >
                                                    Número de carnets<span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    className="form-control border border-dark"
                                                    id={`txtNumeroCarnets`} 
                                                    name="txtNumeroCarnets"
                                                    type="number"
                                                    placeholder="Número de carnets"
                                                    value={selects.selectedNumCarnets}
                                                    disabled={esEditar}
                                                    onChange={(e) => {
                                                        e.persist()
                                                        let num = Number(e.target.value)
                                                        setSelects((v) => ({...v, selectedNumCarnets: e.target.value}))
                                                        if(num){
                                                            onClickNuevoCarnet(num)
                                                        }
                                                    }}
                                                    onBlur={(e) => {
                                                        let num = Number(e.target.value)
                                                        if(!num){
                                                            setSelects((v) => ({...v, selectedNumCarnets: carnets.length}))
                                                        }
                                                    }}
                                                />
                                                <span className="text-danger txtVaciosDf txtNumeroCarnets" style={{ display: 'none' }}>Falta completar (Número de carnets)</span>
                                            </div>
                                        </div>
                                        {/* <div className="col-md-6">
                                        </div> */}
                                        {/* <div className="col-md-6">
                                            <div className="form-group">
                                                <label
                                                    style={{ fontWeight: 600 }}
                                                    htmlFor="fmetodo_pago"
                                                >
                                                    Carnets individuales: <span className="text-danger">{datosEvento.numCarnetInd}</span>
                                                </label><br/>
                                                <label
                                                    style={{ fontWeight: 600 }}
                                                    htmlFor="fmetodo_pago"
                                                >
                                                    Carnets dobles: <span className="text-danger">{datosEvento.numCarnetDoble}</span>
                                                </label><br/>
                                                <label
                                                    style={{ fontWeight: 600 }}
                                                    htmlFor="fmetodo_pago"
                                                >
                                                    Total de asistentes: <span className="text-danger">{datosEvento.totalAsistentes}</span>
                                                </label>
                                            </div>
                                        </div> */}
                                    </div>
                                    </Fragment>
                                    <div className="row">
                                        <div className="col-12 p-0">
                                            <h5 className="mb-3 text-info">Lista de asistentes</h5>
                                        </div>
                                        <div className="col-12 p-0">
                                            {carnets.map((carnet, i) => (
                                                <ItemCarnet
                                                    key={'item'+i}
                                                    indice={i}
                                                    onClickEliminarCarnet={onClickEliminarCarnet}
                                                    catalogos={catalogos}
                                                    carnet={carnet.data}
                                                    listTipoCarnet={selects.listTipoCarnet}
                                                    carnets={carnets}
                                                    datosEvento={datosEvento}
                                                    setDatosEvento={setDatosEvento}
                                                    esEditar={esEditar}
                                                    onClickEditarCarnet={onClickEditarCarnet}
                                                    
                                                />
                                            ))}

                                        </div>
                                    </div>
                                    {!esEditar?<div className="row">
                                        <div className="col-md-12 d-flex justify-content-center">
                                            {/* <div className="d-flex justify-content-center"> */}
                                            <button type="button" style={{ width: '10%' }} className="btn btn-primary btn-lg btn-block mb-4"
                                                onClick={() => { 
                                                    setSelects((v) => ({...v, selectedNumCarnets: carnets.length + 1}))
                                                    onClickNuevoCarnet(1, 2)
                                                }}
                                            >
                                                <i className="fa fa-plus-square" aria-hidden="true"></i>
                                            </button>
                                            {/* </div> */}
                                        </div>
                                    </div>: tipoRegistro == 1 || tipoRegistro == 2? <div className="row">
                                        <div className="col-md-12 d-flex justify-content-center">
                                            {/* <div className="d-flex justify-content-center"> */}
                                            <button type="button" style={{ width: '20%' }} className="btn btn-primary btn-lg btn-block mb-4"
                                                onClick={() => { 
                                                    setModal((s) => ({
                                                        estado: 1,
                                                        datos: {catalogos, idRegistro}
                                                    }))
                                                }}
                                            >
                                                Nuevo Carnet
                                            </button>
                                            {/* </div> */}
                                        </div>
                                    </div>:''}
                                    {!esEditar?
                                    <div className="row">
                                        <div className="col-md-12">
                                            <button 
                                                type="button" 
                                                style={{width: '100%'}} 
                                                className="btn btn-primary btn-lg btn-block mb-4"
                                                disabled={estatusRegistrar}
                                                onClick={() => onClickEnviar()}
                                            >Enviar</button>
                                        </div>
                                    </div>
                                    :''}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
            {modal.estado?
            <ModalCarnetNuevo 
                modal={modal}
                setModal={setModal}
                setRefresh={setRefresh}
                selects={selects}
            />:''}
        </div>
    );
};

export default Default;