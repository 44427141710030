import React, { Fragment, useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import tools from "../../js/tools";
import TablaPro from "../../componentes/tabla";
import ModalEditarCarnet from "./modal_editar";
import ModalImpresion from "./modal_impresion";
import ImgServerr from "../../assets/img/server.gif";
import Select from "react-select";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const Default = () => {
    // const history = useNavigate();
    let server = ''
    const [estatusConexion, setEstatusConexion] = useState(true)
    const [tipoRegistro, setTipoRegistro] = useState(1)
    const [tipoCarnets, setTipoCarnet] = useState([])
    const [selectTipoCarnet, setSelectTipoCarnet] = useState([])
    const [modalEditar, setModalEditar] = useState({ estado: false })
    const [modalImpresion, setModalImpresion] = useState({ estado: false, datos: [] })
    const [esRefresh, setEsRefresh] = useState(true)
    const [qrSelected, setQrSelected] = useState({ estado: false, escaneo: false, datos: {} })
    const [esImprimir, setEsImprimir] = useState(false)
    const [esImprimirDinamica, setEsImprimirDinamica] = useState(false)
    const [filtros, setFiltros] = useState({b: ''})
    let timeout, busqueda_pendiente = '';
    let textQr = '', idTimemOut = null;
    const alertSwal = withReactContent(Swal);

    const evaluarEscribiendo = (e) => {
        if (timeout) {
            clearTimeout(timeout)
        }

        busqueda_pendiente = e.target.value;

        //pagina: busqueda_pendiente.length?0:null,
        timeout = setTimeout(() => {
            setFiltros((s) => ({ ...s, b: busqueda_pendiente, FOLIO_RC: '', ID_RC: '', ID_RAC: '', tipoFiltro: 'buscador'}))
        }, 500)
    }

    const onDecencriptar = async (e) => {
        if(!window.eventos){
            return
        }

        let dec = await window.eventos.decencriptar(e)
        let estatus = false

        // Individual
        if(dec.TIPO_QR === 1){
            estatus = true
            setFiltros((s) => ({ ...s, FOLIO_RC: '', ID_RC: '', ID_RAC: dec.ID_RAC, tipoFiltro: 'buscador'}))
        }
        
        // Grupal
        if(dec.TIPO_QR === 2){
            estatus = true
            setFiltros((s) => ({ ...s, FOLIO_RC: '', ID_RC: dec.ID_RC, ID_RAC: '', tipoFiltro: 'buscador'}))
        }

        if(estatus == false){
            evaluarEscribiendo({ target: { value: '' } })
            return tools.notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `[1] El QR no fue encontrado, si persiste el problema intenta buscar por (Nombre, Apellidos, Correo o Empresa).`,
                time: 5, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        if(dec.ID_RC){
            fetch('/rt_mesa_registro', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({...dec})
            })
            .then((response) => response.json())
            .then((response) => {                
                if(!response.result.length){
                    evaluarEscribiendo({ target: { value: '' } })
                    return tools.notie.alert({
                        type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                        text: `[2] El QR no fue encontrado, si persiste el problema intenta buscar por (Nombre, Apellidos, Correo o Empresa).`,
                        time: 5, // optional, default = 3, minimum = 1,
                        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                    }) 
                }

                setModalImpresion((s) => ({
                    ...s,
                    estado: !s.estado,
                    datos: response.result.map((v) => ({...v, TIPO_QR: dec.TIPO_QR}))
                }))
            })
        }
    }

    const onImprimir = async (datos) => {
        setEsImprimir(datos.ID_RAC)

        if(!window.eventos){
            return
        }

        let response = await window.eventos.imprimir(datos)

        if(!response.estado){
            setEsImprimir(false)

            return tools.notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Error de impresión, revisa que la impresora este conectada correctamente, no tenga etiquetas atascadas o este repetido el dispositivo. Si persiste el error solicita apoyo al equipo de Prointernet.`,
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        setModalImpresion((s) => ({
            ...s,
            datos: s.datos.map((v) => v.ID_RAC === response.datos.ID_RAC? {...v, IMPRIMIO_CARNET_RAC: 1}: v)
        }))
        
        fetch('/rt_mesaregistro_imprimir_carnet', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({...response.datos, alert: 0})
        })
        .then((response) => response.json())
        .then((response) => {
            setEsImprimir(false)

            if(response.estado === 0){
                return tools.notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Error al intentar guardar el estatus de impresión, comunica al equipo de Prointernet para darle una pronta solución.`,
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            setFiltros((s) => ({...s, estado: !s.estado, tipoFiltro: 'buscador'}))

            return tools.notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Imprimiendo ${datos.NOMBRES_DP} ${datos.APELLIDOS_DP}`,
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }

    async function fetchBienvenida(datos) {
        setEsImprimir(datos.ID_RAC);

        if(!window.eventos) {
            return;
        }

        let response = await window.eventos.imprimir_bienvenida(datos);
        
        if(!response.estado){
            setEsImprimir(false);

            return tools.notie.alert({
                type: 'warning',
                text: `Error de impresión, revisa que la impresora este conectada correctamente, no tenga etiquetas atascadas o este repetido el dispositivos. Si persiste el error solicita apoyo al equipo de Prointernet`,
                time: 3,
                podiyion: 'top'
            })
        }

        setModalImpresion((s) => ({
            ...s,
            datos: s.datos.map((v) => v.ID_RAC === response.datos.ID_RAC? {...v, IMPRIMIO_CARNET_RAC: 1}: v)
        }));

        fetch('/rt_mesaregistro_imprimir_carnet', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(response.datos)
        })
        .then((response) => response.json())
        .then((response) => {
            setEsImprimir(false)

            if(response.estado === 0){
                return tools.notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Error al intentar guardar el estatus de impresión, comunica al equipo de Prointernet para darle una pronta solución.`,
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            setFiltros((s) => ({...s, estado: !s.estado, tipoFiltro: 'buscador'}))

            return tools.notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Imprimiendo ${datos.NOMBRES_DP} ${datos.APELLIDOS_DP}`,
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }
    const onImprimirBienvenida = async (datos) => {
        fetch('/rt_bienvenida_impreso', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(datos)
        }).then(response => response.json())
        .then((response) => {
            if(response.isImpreso){
                alertSwal.fire({
                    title: 'El asistente ya fue impreso ¿Quiere imprimirlo nuevamente?',
                    icon: "info",
                    showCancelButton: true,
                    confirmButtonText: "Imprimir",
                    preConfirm: async () => {
                        await fetchBienvenida(datos);
                    }
                }).then((result) => {
                    if(result.isConfirmed) {
                        return tools.notie.alert({
                            type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                            text: `Imprimiendo ${datos.NOMBRES_DP} ${datos.APELLIDOS_DP}`,
                            time: 2, // optional, default = 3, minimum = 1,
                            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                        })
                    }
                })
            }else{
                return fetchBienvenida(datos)
            }
        })
    }

    async function fetchDinamica(datos) {
        setEsImprimirDinamica(true);

        if(!window.eventos) return;
        
        let response = await window.eventos.imprimir_dinamica(datos);

        if(!response.estado){
            setEsImprimirDinamica(false);

            return tools.notie.alert({
                type: 'warning',
                text: `Error de impresión, revisa que la impresora este conectada correctamente, no tenga etiquetas atascadas o este repetido el dispositivos. Si persiste el error solicita apoyo al equipo de Prointernet`,
                time: 3,
                podiyion: 'top'
            })
        }

        setModalImpresion((s) => ({
            ...s,
            datos: s.datos.map((v) => v.ID_RAC === response.datos.ID_RAC ? {...v, IMPRIMIO_CARNET_RAC: 1}: v)
        }))

        fetch('/rt_mesaregistro_imprimir_dinamica', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(response.datos)
        })
        .then((response) => response.json())
        .then((response) => {
            setEsImprimirDinamica(false)

            if(response.estado === 0){
                return tools.notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Error al intentar guardar el estatus de impresión, comunica al equipo de Prointernet para darle una pronta solución.`,
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            setFiltros((s) => ({...s, estado: !s.estado, tipoFiltro: 'buscador'}))

            return tools.notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Imprimiendo ${datos.NOMBRES_DP} ${datos.APELLIDOS_DP}`,
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }

    const onImprimirDinamica = async (datos) => {
        fetch('/rt_dinamica_impreso', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(datos)
        }).then(response => response.json())
        .then((response) => {
            console.log(response)
            if(response.isImpreso){
                alertSwal.fire({
                    title: 'El asistente ya fue impreso ¿Quiere imprimirlo nuevamente?',
                    icon: "info",
                    showCancelButton: true,
                    confirmButtonText: "Imprimir",
                    preConfirm: async () => {
                        await fetchDinamica(datos);
                    }
                }).then((result) => {
                    if(result.isConfirmed) {
                        return tools.notie.alert({
                            type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                            text: `Imprimiendo ${datos.NOMBRES_DP} ${datos.APELLIDOS_DP}`,
                            time: 2, // optional, default = 3, minimum = 1,
                            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                        })
                    }
                })
            }else{
                return fetchDinamica(datos)
            }
        })
    }

    const onEntregaKit = (datos) => {
        fetch('/rt_mesaregistro_entrega_kit', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(datos)
        })
        .then((response) => response.json())
        .then((response) => {
            // setFiltros((s) => ({...s, estado: !s.estado, tipoFiltro: 'buscador'}))
            setEsRefresh((s) => !s)

            return tools.notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Entregando kit a <strong>${datos.NOMBRES_DP} ${datos.APELLIDOS_DP}</strong>`,
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }

    const onFiltroTipoCarnet = (datos) => {
        setSelectTipoCarnet(datos)
        if(!datos){
            return setFiltros((s) => ({ ...s, pagina: 0, b: '', FOLIO_RC: '', ID_RC: '', tipoCarnets: null, ID_RAC: '', tipoFiltro: 'buscador'}))
        }

        let _tipoCarnets = datos.map((v) => v.value)
        setFiltros((s) => ({ ...s, pagina: 0, b: '', FOLIO_RC: '', ID_RC: '', ID_RAC: '', tipoCarnets:_tipoCarnets, tipoFiltro: 'buscador'}))
    }

    const regex = /(?=.*?[0-9])/

    useEffect(() => {
        let isS = true;
        // window.api.recieve("tipoQr", (args) => {
        //     //handle on download-progress event
        // });
        fetch('/rt_catalogos_tipos_carnet', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({})
        })
        .then((response) => response.json())
        .then((response) => {
            if(isS){
                setTipoCarnet(response.catTipoCarnets)
            }
        })
        return () => (isS = false);
    }, []);

    useEffect(() => {
        let isS = true;
        if(window.eventos){
            window.eventos.tipoRegistro()
            .then((response) => {
                if(isS){
                    setTipoRegistro(response)
                }
            })
            .catch(() => {
                if(isS){
                    setTipoRegistro(1)
                }
            })
        }
        return () => (isS = false);
    }, [])

    const verificaConexion = async () => {
        // await window.eventos.reload()
        if(!window.eventos){
          return
        }
  
        server = await window.eventos.ip()
        server += '/conexion' 
        setTimeout(() => {
            fetch(server, {
                method: 'GET',
                headers: {"Content-Type":"application/json"}
            })
            .then(async (response) => {
              if(response.status == 500){
                setEstatusConexion(false)
              }else if(response.status == 200){
                if(estatusConexion == false){
                    setEstatusConexion(true)
                }
              }
              verificaConexion()
            })
            .catch(async (err) => {
              if(estatusConexion){
                console.log('Desconectado')
                setEstatusConexion(false)
              }
              verificaConexion()
            })
        }, 1000)
    }
    
    useEffect(() => {
        let isS = true;
        verificaConexion(isS) 
        return () => (isS = false);
    }, [estatusConexion]);

    const headersRegistro = [
        {
            nombre: 'Folio / Id', tipo: 'html', texto: 'ID_RAC', html: (d) => {
                return <div>
                    <strong>{d.FECHA_ALTA_RAC_TEXT}</strong><br />
                    <span className="text-info"><strong>{d.FOLIO_RC}</strong>-<strong>{d.ID_RAC}</strong></span>
                </div>
            }
        },
        {
            nombre: 'Asistente/Empresa', tipo: 'html', texto: 'NOMBRES_DP', html: (d) => {
                return <div style={{width: '25em'}}>
                    <strong>{d.NOMBRES_DP} {d.APELLIDOS_DP}</strong><br />
                    <span className="text-info"><strong>{d.EMPRESA}</strong></span>
                </div>
            }
        },
        {
            nombre: 'Contacto/Dirección', tipo: 'html', texto: 'ID_RAC', html: (d) => {
                return <div> 
                        {d.CORREO_DP ? <Fragment><strong>{d.CORREO_DP}</strong><br /></Fragment>:<Fragment>Sin correo<br/></Fragment>}
                        {d.WHATSAPP_DP?<Fragment>Cel. {d.WHATSAPP_DP}<br/></Fragment>:<Fragment>Sin teléfono<br/></Fragment>}
                        {d.ESTADO ? <Fragment>{d.ESTADO}, Cd. {d.CIUDAD}</Fragment>:<Fragment>Sin dirección<br/></Fragment>}
                </div>
            }
        },
        {
            nombre: 'Tipo carnet', tipo: 'html', texto: 'ID_RAC', html: (d) => {
                return <div>
                    {d.TIPO_CARNET === 1? 
                        <img src="/convencionista.jpg" width={150} />
                    :d.TIPO_CARNET === 2?
                        <img src="/talleres.jpg" width={150} />
                    :d.TIPO_CARNET === 3? 
                        <img src="/expositor.jpg" width={150} />
                    :d.TIPO_CARNET === 4? 
                        <img src="/invitado.jpg" width={150} />
                    :d.TIPO_CARNET === 5? 
                        <img src="/prensa.jpg" width={150} />
                    :d.TIPO_CARNET === 6? 
                        <img src="/staff.jpg" width={150} />
                    :d.TIPO_CARNET === 7 || d.TIPO_CARNET === 8? 
                        <img src="/cena.jpg" width={150} />
                    :d.TIPO_CARNET === 11? 
                        <img src="/speaker.jpg" width={150} />
                    :d.TIPO_CARNET === 12? 
                    <img src="/patrocinador.jpg" width={150} />
                    :d.TIPO_CARNET === 13? 
                    <img src="/claec.jpg" width={150} />
                    :d.TIPO_CARNET === 9? 
                    <img src="/conferencias.jpg" width={150} />
                    :d.TIPO_CARNET === 8? 
                    <img src="/cena.jpg" width={150} />
                    :d.TIPO_CARNET === 15?
                    <img src="/convencionista-soloCB.jpg" width={150} />
                    :d.TIPO_CARNET === 16?
                    <img src="/convencionista-sinC.jpg" width={150} />
                    :d.TIPO_CARNET === 17?
                    <img src="/apoyo-speaker.jpg" width={150} />
                    : <h5><span className="badge badge-primary">Carnet {d.TIPO_CARNET_TEXT}</span></h5>}
                </div>
            }
        },
        {
            nombre: 'Entrega kit', tipo: 'html', texto: 'ID_RAC', html: (d) => {
                return d.KIT_ENTREGADO_RAC ?
                    <h6><span className="badge badge-success"><span>{d.KIT_ENTREGADO_TEXT}</span><br/><span className="badge badge-light mt-2" style={{fontSize: 10}}>{d.FECHA_KIT_ENTREGADO_TEXT}</span></span></h6>
                    :
                    <h6><span className="badge badge-warning">{d.KIT_ENTREGADO_TEXT}</span></h6>
            }
        },
        {
            nombre: 'Impresión acceso', tipo: 'html', texto: 'ID_RAC', html: (d) => {
                return <div>
                    <Fragment>
                        {d.IMPRIMIO_CARNET_RAC ?
                            <h6><span className="badge badge-success"><span>{d.IMPRIMIO_CARNET_TEXT}</span><br/><span className="badge badge-light mt-2" style={{fontSize: 10}}>{d.ULTIMA_IMPRESION}</span></span></h6>
                            :
                            <h6><span className="badge badge-warning">{d.IMPRIMIO_CARNET_TEXT}</span></h6>
                        }
                    </Fragment>
                </div>
            }
        },
        // {
        //     nombre: 'Impresión dinamica', tipo: 'html', texto: 'ID_RAC', html: (d) => {
        //         return <div>
        //             <Fragment>
        //                 {d.IMPRIMIO_CARNET_RAC ?
        //                     <h6><span className="badge badge-success"><span>{d.IMPRIMIO_CARNET_TEXT}</span><br/><span className="badge badge-light mt-2" style={{fontSize: 10}}>{d.ULTIMA_IMPRESION}</span></span></h6>
        //                     :
        //                     <h6><span className="badge badge-warning">{d.IMPRIMIO_CARNET_TEXT}</span></h6>
        //                 }
        //             </Fragment>
        //         </div>
        //     }
        // },
        {
            nombre: 'Estatus pago', tipo: 'html', texto: 'ID_RAC', html: (d) => {
                return <div>
                    <Fragment>
                        <div style={{textTransform: 'uppercase'}}>
                            {d.ESTATUS_DPG === -1 ?
                            <h6><span className="badge badge-dark">{d.ESTATUS_DPG_TEXT} Pago</span></h6>
                            : d.ESTATUS_DPG === 0 ?
                            <h6><span className="badge badge-danger">Pago {d.ESTATUS_DPG_TEXT}</span></h6>
                            : d.ESTATUS_DPG === 1 ?
                            <h6><span className="badge badge-light">Pago {d.ESTATUS_DPG_TEXT}</span></h6>
                            : d.ESTATUS_DPG === 2 ?
                            <h6><span className="badge badge-success">{d.ESTATUS_DPG_TEXT}</span></h6>
                            : d.ESTATUS_DPG === 3 ?
                            <h6><span className="badge badge-warning">Pago {d.ESTATUS_DPG_TEXT}</span></h6>
                            : d.ESTATUS_DPG === 4 ?
                            <h6><span className="badge badge-dark">{d.ESTATUS_DPG_TEXT}</span></h6>
                            : d.ESTATUS_DPG === 5 ?
                            <h6><span className="badge badge-info">{d.ESTATUS_DPG_TEXT}</span></h6>
                            :d.ESTATUS_DPG === 6 ?
                            <h6><span className="badge badge-info">{d.ESTATUS_DPG_TEXT}</span></h6>
                            :'Sin estatus'}
                        </div>
                    </Fragment>
                </div>
            }
        },
        // { nombre: 'Fecha', tipo: 'individual', texto: 'FECHA_ALTA_RAC_TEXT' },
        {
            nombre: 'Detalles', tipo: 'html', texto: 'Detalle',
            html: (datos) => {
                return <div>
                    <div className="btn-group dropstart dropleft">
                        <button
                            className="btn btn-secondary dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            Opciones
                        </button>
                        <ul
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton1"
                        >
                            <li>
                                <a className="dropdown-item" href=""
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setModalImpresion((s) => ({
                                            ...s,
                                            estado: !s.estado,
                                            datos: [{...datos, TIPO_QR: 1}]
                                        }))
                                    }}
                                >Imprimir carnet</a>
                            </li>
                            <li>
                                <a className="dropdown-item"
                                    href=""
                                    onClick={(e) => {
                                        e.preventDefault()
                                        onEntregaKit(datos)                                                         
                                    }}
                                >Entrega de kit</a>
                            </li>
                            <li>
                                <a className="dropdown-item" href=""
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setModalEditar((s) => ({
                                            ...s,
                                            estado: !s.estado,
                                            datos
                                        }))
                                    }}
                                >Editar asistente</a></li>
                            <li>
                                <a className="dropdown-item"
                                    href=""
                                    onClick={(e) => {
                                        e.preventDefault()
                                        window.location.href = '/mesaregistro/editar/'+datos.ID_RC                                                           
                                    }}
                                >Detalle del registro</a>
                            </li>
                        </ul>
                    </div>
                </div>
            }
        }
    ]
    
    return (
        <div>
            <Fragment>
                <div className="col-sm-12 mt-3"
                    onClick={() => {
                        if (qrSelected.estado) {
                            setQrSelected((v) => ({ ...v, estado: false }))
                        }
                    }}
                >
                <div className="card">
                    <div className="card-header" style={{paddingTop: 0, paddingBottom: 0}}>
                        <div className='row'>
                                {/* <div className='col-sm-12 mb-2 d-flex justify-content-center'>
                                    <h5>Mesa de Registro</h5>
                                </div> */}
                                <div className="col-xl-12 p-0 m-0" style={{position: 'absolute'}}>
                                    {/* <strong> */}
                                    {estatusConexion == false?
                                    <button className="btn btn-danger btn-sm" 
                                    onClick={async () => {
                                        if(window.eventos){
                                            await window.eventos.paginaCargando()
                                        }
                                    }}>Sin conexión, reintentando...</button>:<img src={ImgServerr} width={40}/>}
                                    {/* </strong> */}
                                </div>
                                <div className="col-xl-12 p-0">
                                    <div className="row">
                                        <div className="col-md-12 text-center">
                                            <h3 className="titulo-expo">ONEXPO</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-header p-0">
                            <div className="row" style={{position: 'absolute', right: '0%', top: '0%'}}>
                                <div className="col-md-12 d-flex justify-content-end align-items-center">
                                    <a href="" style={{backgroundColor: qrSelected.estado ? '#39F203' : 'white'}}
                                        onKeyDown={(e) => {
                                            if (e.key !== 'Enter') {
                                                textQr += e.key
                                            }
                                        }}
                                        onKeyUp={() => {
                                            window.clearTimeout(idTimemOut)
                                            idTimemOut = setTimeout(async () => {
                                                if (textQr.length) {
                                                    // window.api.send("imprimir", {textQr, datos: { nombre: 'Daniel' } });
                                                    await onDecencriptar({ textQr })
                                                    textQr = ''
                                                }
                                            }, 1000)
                                        }}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            setQrSelected((v) => ({ ...v, estado: true }))
                                        }}>
                                        <img src="/scan.png" width={60} />
                                    </a>
                                </div>
                            </div>
                            <div className='row'>
                                {/* <div className='col-sm-12 mb-2 d-flex justify-content-center'>
                                    <h5>Mesa de Registro</h5>
                                </div> */}
                                <div className="col-xl-8 mb-2 mt-3">
                                    <div className="row">
                                        <div className="col-md-7">
                                            <label style={{ fontWeight: 600, fontSize: 16 }} htmlFor="buscar">
                                                Buscador
                                            </label>
                                            <input
                                                id="input_buscador"
                                                type="text"
                                                className="form-control"
                                                name="buscar"
                                                aria-label=""
                                                aria-describedby="basic-addon1"
                                                placeholder="Buscar por (nombres, apellidos, correo electrónico y nombre de empresa)"
                                                onKeyUp={evaluarEscribiendo}
                                            />
                                        </div>
                                        <div className=" col-md-2">
                                            <label style={{ fontWeight: 600, fontSize: 16 }} htmlFor="buscar">
                                                Folio
                                            </label>
                                            <input
                                                id="txtRegistro"
                                                type="text"
                                                className="form-control"
                                                name="buscar"
                                                aria-label=""
                                                aria-describedby="basic-addon1"
                                                value={filtros.FOLIO_RC? filtros.FOLIO_RC: ''}
                                                onChange={(e) => {
                                                    e.persist()
                                                    setFiltros((s) => ({ ...s, FOLIO_RC: e.target.value, tipoFiltro: 'buscador'}))
                                                }}
                                            />
                                        </div>
                                        <div className=" col-md-3">
                                            <label style={{ fontWeight: 600, fontSize: 16 }} htmlFor="buscar">
                                                Tipo de carnet
                                            </label>
                                            <Select
                                                options={tipoCarnets}
                                                isMulti
                                                placeholder={"Tipo carnet"}
                                                value={selectTipoCarnet}
                                                onChange={onFiltroTipoCarnet}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 d-flex mb-sm-2 mb-3 justify-content-end align-self-end">
                                    <div className="flex justify-content-start align-self-center">
                                        <button type="button" className="btn btn-success" onClick={() => {
                                            window.document.getElementById('input_buscador').value = ''
                                            //evaluarEscribiendo({ target: { value: '' } })
                                            setFiltros((s) => ({ ...s, pagina: 0, b: '', FOLIO_RC: '', ID_RC: '', ID_RAC: '', tipoFiltro: 'buscador'}))
                                        }}>
                                            <i className="fa fa-refresh"></i> Limpiar filtro
                                        </button>
                                        {tipoRegistro == 1 || tipoRegistro == 2?
                                        <button type="button" className="btn btn-primary ml-2" onClick={() => {
                                            window.location.href = '/mesaregistro/nuevo'
                                        }}>
                                            <i className="fa fa-address-card" aria-hidden="true"></i> Nuevo registro
                                        </button>
                                        :''}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" datatable-react mt-4" >
                            <TablaPro
                                cargando={false}
                                headers={[
                                    {
                                        nombre: 'Folio / Id', tipo: 'html', texto: 'ID_RAC', html: (d) => {
                                            return <div>
                                                <strong>{d.FECHA_ALTA_RAC_TEXT}</strong><br />
                                                <span className="text-info"><strong>{d.FOLIO_RC}</strong>-<strong>{d.ID_RAC}</strong></span>
                                            </div>
                                        }
                                    },
                                    {
                                        nombre: 'Asistente/Empresa', tipo: 'html', texto: 'NOMBRES_DP', html: (d) => {
                                            return <div style={{width: '25em'}}>
                                                <strong>{d.NOMBRES_DP} {d.APELLIDOS_DP}</strong><br />
                                                <span className="text-info"><strong>{d.EMPRESA}</strong></span><br />
                                                {/* <span className="text-info" style={{color: 'black'}}> {
                                                d.CENA_BIENVENIDA.match(regex) != null && d.CENA_CLAUSURA.match(regex) != null ? `${d.CENA_BIENVENIDA} / ${d.CENA_CLAUSURA}`
                                                :d.CENA_BIENVENIDA.match(regex) != null && d.CENA_CLAUSURA.match(regex) == null ? `${d.CENA_BIENVENIDA}`
                                                :d.CENA_BIENVENIDA.match(regex) == null && d.CENA_CLAUSURA.match(regex) != null ? `${d.CENA_CLAUSURA}`
                                                : ''
                                                }</span> */}
                                            </div>
                                        }
                                    },
                                    {
                                        nombre: 'Contacto/Dirección', tipo: 'html', texto: 'ID_RAC', html: (d) => {
                                            return <div> 
                                                    {d.CORREO_DP ? <Fragment><strong>{d.CORREO_DP}</strong><br /></Fragment>:<Fragment>Sin correo<br/></Fragment>}
                                                    {d.WHATSAPP_DP?<Fragment>Cel. {d.WHATSAPP_DP}<br/></Fragment>:<Fragment>Sin teléfono<br/></Fragment>}
                                                    {d.ESTADO ? <Fragment>{d.ESTADO}, Cd. {d.CIUDAD}</Fragment>:<Fragment>Sin dirección<br/></Fragment>}
                                            </div>
                                        }
                                    },
                                    {
                                        nombre: 'Tipo carnet', tipo: 'html', texto: 'ID_RAC', html: (d) => {
                                            return <div>
                                                {d.TIPO_CARNET === 1? 
                                                    <img src="/convencionista.jpg" width={150} />
                                                :d.TIPO_CARNET === 2?
                                                    <img src="/talleres.jpg" width={150} />
                                                :d.TIPO_CARNET === 3? 
                                                    <img src="/expositor.jpg" width={150} />
                                                :d.TIPO_CARNET === 4? 
                                                    <img src="/invitado.jpg" width={150} />
                                                :d .TIPO_CARNET === 5? 
                                                    <img src="/prensa.jpg" width={150} />
                                                :d.TIPO_CARNET === 6? 
                                                    <img src="/staff.jpg" width={150} />
                                                :d.TIPO_CARNET === 7 || d.TIPO_CARNET === 8? 
                                                    <img src="/cena.jpg" width={150} />
                                                :d.TIPO_CARNET === 11? 
                                                    <img src="/speaker.jpg" width={150} />
                                                :d.TIPO_CARNET === 12? 
                                                <img src="/patrocinador.jpg" width={150} />
                                                :d.TIPO_CARNET === 13? 
                                                <img src="/claec.jpg" width={150} />
                                                :d.TIPO_CARNET === 9? 
                                                <img src="/conferencias.jpg" width={150} />
                                                :d.TIPO_CARNET === 8? 
                                                <img src="/cena.jpg" width={150} />
                                                :d.TIPO_CARNET === 15?
                                                <img src="/convencionista-soloCB.jpg" width={150} />
                                                :d.TIPO_CARNET === 16?
                                                <img src="/convencionista-sinC.jpg" width={150} />
                                                :d.TIPO_CARNET === 17?
                                                <img src="/apoyo-speaker.jpg" width={150} />
                                                : <h5><span class="badge badge-primary">Carnet {d.TIPO_CARNET_TEXT}</span></h5>}
                                            </div>
                                        }
                                    },
                                    {
                                        nombre: 'Entrega kit', tipo: 'html', texto: 'ID_RAC', html: (d) => {
                                            return d.KIT_ENTREGADO_RAC ?
                                                <h6><span class="badge badge-success"><span>{d.KIT_ENTREGADO_TEXT}</span><br/><span class="badge badge-light mt-2" style={{fontSize: 10}}>{d.FECHA_KIT_ENTREGADO_TEXT}</span></span></h6>
                                                :
                                                <h6><span class="badge badge-warning">{d.KIT_ENTREGADO_TEXT}</span></h6>
                                        }
                                    },
                                     
                                    {
                                        nombre: 'Impresión acceso', tipo: 'html', texto: 'ID_RAC', html: (d) => {
                                            return <div>
                                                <Fragment>
                                                    {d.IMPRIMIO_CARNET_RAC ?
                                                        <h6><span class="badge badge-success"><span>{d.IMPRIMIO_CARNET_TEXT}</span><br/><span class="badge badge-light mt-2" style={{fontSize: 10}}>{d.ULTIMA_IMPRESION}</span></span></h6>
                                                        :
                                                        <h6><span class="badge badge-warning">{d.IMPRIMIO_CARNET_TEXT}</span></h6>
                                                    }
                                                </Fragment>
                                            </div>
                                        }
                                    },
                                    {
                                        nombre: 'Estatus pago', tipo: 'html', texto: 'ID_RAC', html: (d) => {
                                            return <div>
                                                <Fragment>
                                                    <div style={{textTransform: 'uppercase'}}>
                                                        {d.ESTATUS_DPG === -1 ?
                                                        <h6><span class="badge badge-dark">{d.ESTATUS_DPG_TEXT} Pago</span></h6>
                                                        : d.ESTATUS_DPG === 0 ?
                                                        <h6><span class="badge badge-danger">Pago {d.ESTATUS_DPG_TEXT}</span></h6>
                                                        : d.ESTATUS_DPG === 1 ?
                                                        <h6><span class="badge badge-light">Pago {d.ESTATUS_DPG_TEXT}</span></h6>
                                                        : d.ESTATUS_DPG === 2 ?
                                                        <h6><span class="badge badge-success">{d.ESTATUS_DPG_TEXT}</span></h6>
                                                        : d.ESTATUS_DPG === 3 ?
                                                        <h6><span class="badge badge-warning">Pago {d.ESTATUS_DPG_TEXT}</span></h6>
                                                        : d.ESTATUS_DPG === 4 ?
                                                        <h6><span class="badge badge-dark">{d.ESTATUS_DPG_TEXT}</span></h6>
                                                        : d.ESTATUS_DPG === 5 ?
                                                        <h6><span class="badge badge-info">{d.ESTATUS_DPG_TEXT}</span></h6>
                                                        :d.ESTATUS_DPG === 6 ?
                                                        <h6><span class="badge badge-info">{d.ESTATUS_DPG_TEXT}</span></h6>
                                                        :'Sin estatus'}
                                                    </div>
                                                </Fragment>
                                            </div>
                                        }
                                    },
                                    // { nombre: 'Fecha', tipo: 'individual', texto: 'FECHA_ALTA_RAC_TEXT' },
                                    {
                                        nombre: 'Detalles', tipo: 'html', texto: 'Detalle',
                                        html: (datos) => {
                                            return <div>
                                                <div class="btn-group dropstart dropleft">
                                                    <button
                                                        class="btn btn-secondary dropdown-toggle"
                                                        type="button"
                                                        id="dropdownMenuButton1"
                                                        data-bs-toggle="dropdown"
                                                        aria-expanded="false"
                                                    >
                                                        Opciones
                                                    </button>
                                                    <ul
                                                        class="dropdown-menu"
                                                        aria-labelledby="dropdownMenuButton1"
                                                    >
                                                        <li>
                                                            <a class="dropdown-item" href=""
                                                                onClick={(e) => {
                                                                    e.preventDefault()
                                                                    
                                                                    setModalImpresion((s) => ({
                                                                        ...s,
                                                                        estado: !s.estado,
                                                                        datos: [{...datos, TIPO_QR: 1}]
                                                                    }))
                                                                }}
                                                            >Imprimir carnet</a>
                                                        </li>
                                                        <li>
                                                            <a className="dropdown-item"
                                                                href=""
                                                                onClick={(e) => {
                                                                    e.preventDefault()
                                                                    onEntregaKit(datos)                                                         
                                                                }}
                                                            >Entrega de kit</a>
                                                        </li>
                                                        <li>
                                                            <a class="dropdown-item" href=""
                                                                onClick={(e) => {
                                                                    e.preventDefault()
                                                                    setModalEditar((s) => ({
                                                                        ...s,
                                                                        estado: !s.estado,
                                                                        datos
                                                                    }))
                                                                }}
                                                            >Editar asistente</a></li>
                                                        <li>
                                                            <a className="dropdown-item"
                                                                href=""
                                                                onClick={(e) => {
                                                                    e.preventDefault()
                                                                    window.location.href = '/mesaregistro/editar/'+datos.ID_RC                                                           
                                                                }}
                                                            >Detalle del registro</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        }
                                    }
                                ]}
                                // row={{
                                //     ignoreColumns: ['Detalle'],
                                //     onClick: (e, datos) => {
                                //         setModalEditar((s) => ({
                                //             ...s,
                                //             estado: !s.estado,
                                //             datos
                                //         }))
                                //     }
                                // }}
                                totalField={'NR'} /* NR = número de resultados totales */
                                path={'./rt_mesa_registro'}
                                refresh={esRefresh}
                                body={{...filtros, tipoRegistro}}
                                rowsPerPage={20}
                                paginadorTop={true}
                            ></TablaPro>
                        </div>
                    </div>
                </div>
                <ModalEditarCarnet
                    modal={modalEditar}
                    setModal={setModalEditar}
                    setEsRefresh={setEsRefresh}
                />
                <ModalImpresion
                    modal={modalImpresion}
                    setModal={setModalImpresion}
                    setEsRefresh={setEsRefresh}
                    onImprimirBienvenida={onImprimirBienvenida}
                    onImprimirDinamica={onImprimirDinamica}
                    esImprimir={esImprimir}
                    esImprimirDinamica={esImprimirDinamica}
                />
            </Fragment>
        </div>
    );
};

export default Default;
